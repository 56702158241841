<template>
  <div class="archive">
    <div class="container-xl">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <div class="card-title">
            Архив проектов
          </div>
          <div>
            <a class="nav-link "
               href="#list-filter"
               id="filter"
               role="button"
               data-toggle="collapse"
               aria-expanded="false"
            >
              Фильтры
            </a>
          </div>
        </div>
        <paginated-list :count="collection.count"
                        :filter="filter"
                        :total-count="collection.totalCount"
                        :data-loading="collection.loading"
                        @update="loadData"
        >
          <template v-slot:filter>
            <archived-list-filter :filter="filter"/>
          </template>
          <template v-slot:loading>
            <div class="card">
              <div class="card-body">
                <i>Загрузка...</i>
              </div>
            </div>
          </template>
          <template v-slot:no-items>
            <div class="card">
              <div class="card-body">
                <i>Нет данных</i>
              </div>
            </div>
          </template>
          <div class="card-body">
            <div class="archived-projects-grid">
              <div v-for="item in collection.items" class="card financed-project">
                <div class="card-header financed-project__header">
                  <div class="card-title">
                    {{ item.name }}
                  </div>
                </div>
                <div class="card-body financed-project__body">
                  <div class="financed-project__photo-holder">
                    <img style="width: 100%" :src="item.photo"/>
                    <div v-if="isFinanced(item)" class="financed-project__status">
                      Проинвестирован
                    </div>
                  </div>
                  <div class="financed-project__info">
                    <div>
                      Стоимость залога:
                    </div>
                    <div class="font-weight-bold">
                      <Money :money="item.collateralSum"/>
                    </div>
                  </div>
                  <div class="financed-project__info">
                    <div>
                      Ставка
                    </div>
                    <div class="font-weight-bold">
                      {{ item.interestRate }}%
                    </div>
                  </div>
                  <div class="financed-project__info">
                    <div>
                      Срок:
                    </div>
                    <div class="font-weight-bold">
                      {{ item.initialTerm }} мес.
                    </div>
                  </div>
                  <div class="financed-project__info">
                    <div>
                      Цель
                    </div>
                    <div class="font-weight-bold">
                      <Money :money="item.targetSum"/>
                    </div>
                  </div>
                  <div class="financed-project__footer">
                    <router-link :to="{ name: 'archivedProjectDetails', params: { uuid: item.uuid } }">
                      Подробнее
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </paginated-list>
      </div>
    </div>
  </div>
</template>

<script>
import {DataCollection, Money, PaginatedList} from "@/components/common";
import {createArchivedProjectProvider} from "@/utils/di";
import ArchivedListFilter from "@/components/dashboard/components/archived/ArchivedListFilter.vue";

export default {
  name: "ArchivedProjects",
  components: {
    ArchivedListFilter,
    PaginatedList,
    Money
  },
  data() {
    return {
      collection: new DataCollection(),
      filter: {}
    }
  },
  methods: {
    async loadData(page, perPage, filter) {
      this.collection.startLoading();
      try {
        const archivedProjectsProvider = await createArchivedProjectProvider();
        const collection = await archivedProjectsProvider.getList(filter, page, perPage);
        this.collection.setCollection(collection);
      } finally {
        this.collection.finishLoading();
      }
    },
    countRemainder(item) {
      return {
        amount: item.targetSum.amount - (item.investedSum?.amount || 0),
        currencyCode: item.targetSum.currencyCode,
      }
    },
    isFinanced(item) {
      return this.countRemainder(item).amount === 0
    }
  }
}
</script>

<style lang="scss">
#filter {
  color: #21e58a;
  font-size: 90%;
  font-weight: 500;
}
.archived-projects-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.financed-project {
  &__photo-holder {
    min-height: 150px;
    position: relative;

    & > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__status {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background-color: opacify(blue, 0.5);
    padding: 3px 10px;
    border-radius: 20px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__footer {
    & > a {
      display: block;
      color: #21e58a;
      text-align: center;
      font-size: 1.2em;
      transition: color 0.3s;

      &:hover {
        color: darken(#21e58a, 15%);
        text-decoration: none;
      }
    }
  }
}
</style>